import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 898.000000 875.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,875.000000) scale(0.100000,-0.100000)">
<path d="M0 4375 l0 -4375 4490 0 4490 0 0 4375 0 4375 -4490 0 -4490 0 0
-4375z m5052 2198 c30 -54 90 -159 133 -233 43 -74 89 -155 103 -180 13 -25
39 -70 58 -100 90 -152 284 -493 284 -501 0 -5 5 -9 10 -9 6 0 10 -5 10 -10 0
-6 25 -52 55 -102 30 -50 55 -93 55 -95 0 -2 23 -43 51 -91 29 -48 60 -102 70
-119 11 -18 27 -48 38 -65 10 -18 56 -98 101 -176 83 -144 83 -144 114 -138
175 36 301 27 440 -31 184 -76 324 -230 392 -428 24 -69 28 -96 28 -200 0 -96
-4 -134 -22 -190 -59 -190 -172 -326 -342 -414 -339 -174 -764 -33 -926 309
-20 41 -39 82 -44 90 -6 13 -60 15 -379 18 l-371 2 0 340 0 340 209 0 c139 0
212 4 216 11 4 6 -7 33 -24 60 -17 28 -31 53 -31 56 0 3 -22 42 -49 87 -27 44
-58 97 -69 116 -50 89 -286 498 -352 610 -73 125 -127 219 -182 318 -49 87
-11 139 -404 -546 -52 -89 -105 -180 -119 -202 -14 -22 -25 -42 -25 -44 0 -2
-27 -50 -60 -105 -33 -56 -60 -103 -60 -105 0 -1 -29 -52 -65 -112 -36 -60
-65 -117 -65 -126 0 -17 17 -18 210 -18 l210 0 0 -340 0 -340 -370 0 -369 0
-39 -84 c-75 -159 -173 -263 -315 -334 -104 -52 -187 -72 -307 -72 -197 0
-360 69 -496 208 -69 71 -116 147 -157 254 -29 79 -31 90 -31 223 0 125 3 148
26 212 78 218 229 370 437 440 118 39 287 42 417 7 22 -6 9 -26 202 306 49 85
99 171 109 190 11 19 46 80 78 135 163 282 213 368 260 450 29 50 96 167 150
260 54 94 115 199 135 235 35 62 123 214 170 293 l22 37 412 0 412 0 56 -97z
m-1494 -3810 l2 -233 -85 0 -85 0 0 228 c0 126 3 232 7 236 4 4 41 5 83 4 l75
-3 3 -232z m1800 2 l0 -235 -94 0 -94 0 0 235 0 235 94 0 95 0 -1 -235z m949
-2 l2 -233 -94 0 -95 0 0 235 0 236 93 -3 92 -3 2 -232z m1248 213 c87 -37
134 -111 135 -211 0 -111 -33 -171 -121 -217 -40 -21 -56 -23 -189 -23 l-145
0 -3 232 -2 232 27 4 c65 11 258 0 298 -17z m-5711 -113 c21 -71 49 -166 63
-213 13 -47 27 -93 30 -102 4 -16 -5 -18 -87 -18 l-90 0 -7 30 c-6 32 -31 45
-74 38 -16 -2 -26 -13 -33 -35 l-9 -33 -88 0 c-49 0 -89 4 -89 9 0 4 4 21 9
37 5 16 28 94 51 174 23 80 48 166 57 193 l15 47 107 0 108 0 37 -127z m457
104 c59 -31 81 -74 76 -149 -2 -44 -9 -64 -27 -83 -27 -29 -24 -39 40 -136 22
-33 40 -62 40 -65 0 -2 -42 -4 -93 -4 l-92 0 -35 70 c-46 90 -60 91 -60 0 l0
-70 -80 0 -80 0 0 230 0 230 133 0 c120 0 138 -2 178 -23z m325 -1 c3 -13 5
-30 4 -37 -6 -38 1 -112 9 -103 5 5 23 44 41 87 l32 77 89 0 c52 0 89 -4 89
-10 0 -5 -25 -57 -56 -116 l-56 -106 31 -49 c17 -28 31 -52 31 -54 0 -3 16
-29 35 -59 19 -30 35 -58 35 -61 0 -3 -42 -5 -93 -5 l-93 0 -42 86 c-24 48
-45 84 -48 81 -3 -3 -6 -40 -7 -84 l-2 -78 -82 -3 -83 -3 0 231 0 230 80 0
c75 0 80 -1 86 -24z m714 -51 l0 -75 -65 0 -65 0 0 -155 0 -155 -82 0 -83 0 0
152 0 153 -62 3 -63 3 0 74 0 75 210 0 210 0 0 -75z m440 73 c1 -2 11 -56 23
-121 12 -65 26 -119 31 -120 7 -2 35 117 56 241 0 4 180 2 180 -3 0 -9 -122
-439 -126 -447 -7 -11 -211 -10 -218 1 -5 8 -126 435 -126 446 0 5 179 8 180
3z m685 -63 l0 -60 -93 -3 c-82 -2 -93 -5 -90 -20 3 -14 17 -17 86 -20 l82 -3
0 -59 0 -59 -82 -3 c-70 -3 -83 -6 -86 -20 -3 -16 7 -18 97 -18 l101 0 0 -65
0 -65 -180 0 -180 0 0 230 0 230 173 -2 172 -3 0 -60z m487 -87 l3 -153 85 -5
85 -5 3 -72 3 -72 -171 -3 c-95 -2 -174 -1 -176 1 -2 2 -4 107 -4 233 l0 228
85 0 84 0 3 -152z m719 122 c4 -14 20 -64 34 -113 27 -89 37 -99 50 -50 3 16
18 65 31 111 l25 82 125 0 124 0 0 -230 0 -230 -90 0 -90 0 -1 113 c-1 121 0
121 -45 -25 l-26 -88 -72 0 -72 0 -36 113 -36 112 -1 -113 -1 -113 -87 3 -88
3 -3 215 c-1 118 0 220 3 228 3 10 33 12 126 10 121 -3 122 -3 130 -28z m1097
-47 l3 -73 -66 0 -65 0 0 -155 0 -155 -85 0 -84 0 -3 153 -3 152 -62 3 -63 3
0 75 0 74 213 -2 212 -3 3 -72z m402 12 l0 -64 -92 -3 c-62 -2 -93 -7 -96 -15
-3 -10 18 -13 82 -13 l86 0 0 -70 0 -70 -85 0 c-69 0 -85 -3 -85 -15 0 -12 18
-15 100 -15 l100 0 0 -65 0 -65 -185 0 -185 0 0 230 0 230 180 0 180 0 0 -65z
m-4320 -761 c0 -95 -3 -114 -15 -114 -12 0 -15 19 -15 113 0 63 3 117 6 120
18 17 24 -14 24 -119z m1028 3 c2 -100 0 -117 -13 -117 -13 0 -15 19 -15 121
0 92 3 120 13 117 8 -3 13 -39 15 -121z m2642 3 c0 -73 -4 -120 -10 -120 -6 0
-10 47 -10 120 0 73 4 120 10 120 6 0 10 -47 10 -120z m140 0 c0 -73 -4 -120
-10 -120 -6 0 -10 47 -10 120 0 73 4 120 10 120 6 0 10 -47 10 -120z m-4998 8
c52 -130 51 -128 40 -128 -6 0 -17 12 -24 28 -11 22 -21 28 -55 30 -38 3 -43
0 -58 -27 -14 -28 -35 -42 -35 -23 0 13 84 212 90 212 3 0 22 -42 42 -92z
m1773 78 c72 -30 85 -129 24 -180 -24 -20 -42 -26 -80 -26 l-49 0 0 110 0 110
35 0 c20 0 51 -7 70 -14z m2245 5 c0 -13 -26 -21 -66 -21 -32 0 -34 -2 -34
-35 l0 -35 50 0 c61 0 68 -20 8 -21 -24 -1 -46 -1 -50 0 -5 0 -8 -17 -8 -39
l0 -40 50 0 c28 0 50 -4 50 -10 0 -6 -28 -10 -65 -10 l-65 0 0 103 c0 57 3
107 7 110 10 10 123 8 123 -2z m-3590 -46 c0 -16 6 -25 15 -25 8 0 15 -4 15
-9 0 -5 -7 -11 -15 -15 -11 -4 -15 -21 -15 -61 0 -42 -3 -55 -15 -55 -12 0
-15 13 -15 60 0 47 -3 60 -15 60 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 8
15 18 0 10 3 22 7 25 13 14 23 6 23 -18z m158 14 c-2 -6 -8 -10 -13 -10 -5 0
-11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m1770 0 c-2 -6 -8 -10 -13
-10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15 -5 13 -11z m767 1 c3 -5 -1 -10
-9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-2874 -47 c16 -10
29 -23 29 -30 0 -17 -10 -16 -31 3 -21 19 -67 12 -76 -11 -8 -22 5 -62 23 -69
8 -3 29 1 46 10 37 19 48 11 23 -16 -27 -30 -82 -24 -108 11 -25 35 -16 84 21
104 35 18 38 18 73 -2z m798 -29 c16 -36 7 -44 -55 -44 -56 0 -69 -11 -33 -31
16 -8 27 -8 45 2 28 15 42 6 24 -16 -18 -22 -70 -19 -97 7 -26 25 -30 64 -8
95 28 40 103 32 124 -13z m1014 18 c9 -8 17 -26 17 -39 0 -21 -4 -23 -55 -23
-56 0 -67 -8 -40 -30 12 -10 21 -10 45 0 36 15 53 4 26 -16 -26 -18 -66 -18
-94 2 -16 11 -22 25 -22 53 0 70 69 99 123 53z m740 0 c9 -8 17 -26 17 -39 0
-21 -4 -23 -55 -23 -57 0 -70 -11 -34 -31 16 -8 27 -8 45 2 30 16 46 2 20 -17
-49 -35 -120 -2 -118 55 2 69 71 99 125 53z m1665 6 c14 -12 16 -38 3 -38 -5
0 -14 7 -21 15 -16 19 -44 19 -64 -1 -23 -23 -20 -51 9 -70 23 -15 27 -15 51
1 32 21 43 12 19 -15 -39 -43 -115 -12 -117 47 -2 66 68 102 120 61z m253 -20
c19 -36 4 -48 -58 -48 l-57 0 21 -22 c19 -19 26 -20 48 -10 39 18 50 15 30 -8
-27 -30 -78 -27 -104 6 -35 44 -22 93 30 112 30 12 76 -4 90 -30z m530 0 c19
-35 4 -48 -56 -48 -57 0 -70 -11 -34 -31 16 -8 28 -8 46 0 37 17 48 13 28 -9
-27 -30 -78 -27 -104 6 -12 15 -21 34 -21 43 0 24 29 61 54 70 27 11 74 -6 87
-31z m462 14 c11 -10 14 -19 8 -25 -6 -6 -16 -4 -26 7 -23 23 -52 20 -70 -8
-30 -45 16 -91 61 -61 29 19 34 19 34 0 0 -18 -31 -35 -62 -35 -32 0 -68 35
-68 66 0 72 69 103 123 56z m214 18 c30 -11 58 -53 48 -71 -4 -5 -31 -9 -61
-9 -56 0 -69 -11 -33 -31 16 -8 28 -8 47 0 29 14 40 7 22 -14 -18 -22 -72 -19
-98 5 -36 34 -24 102 21 120 12 5 22 9 23 9 1 1 15 -4 31 -9z m-5337 -70 c0
-56 -3 -70 -15 -70 -12 0 -15 14 -15 70 0 56 3 70 15 70 12 0 15 -14 15 -70z
m143 28 c7 -24 17 -43 22 -43 4 0 15 19 24 43 8 23 21 42 28 42 7 0 13 -1 13
-3 0 -6 -57 -138 -62 -142 -3 -3 -12 11 -21 32 -8 21 -23 55 -31 76 -14 31
-14 37 -2 37 8 0 21 -19 29 -42z m595 2 c9 -22 21 -40 25 -40 5 0 16 18 24 40
9 22 22 40 30 40 11 0 11 -5 -1 -32 -7 -18 -28 -68 -46 -110 -18 -43 -39 -78
-46 -78 -18 0 -18 -1 6 54 l21 46 -26 53 c-28 60 -30 67 -14 67 6 0 18 -18 27
-40z m1032 -31 c0 -52 -3 -70 -12 -67 -8 3 -14 29 -16 71 -2 55 0 67 13 67 12
0 15 -14 15 -71z m142 24 l20 -48 20 48 c20 45 38 62 38 33 0 -21 -49 -126
-58 -126 -7 0 -62 120 -62 135 0 3 5 5 10 5 6 0 20 -21 32 -47z m504 36 c3 -5
-6 -13 -20 -19 -23 -8 -26 -15 -26 -60 0 -38 -4 -50 -15 -50 -12 0 -15 14 -15
69 l0 69 35 0 c19 0 38 -4 41 -9z m115 -6 c4 -66 0 -118 -10 -121 -7 -2 -11
19 -11 67 0 41 4 71 10 71 6 0 10 -8 11 -17z m240 -10 c13 -35 8 -105 -8 -111
-10 -3 -13 12 -13 52 0 44 -4 58 -17 63 -25 10 -53 -32 -53 -79 0 -27 -4 -38
-12 -35 -14 4 -19 52 -11 102 4 29 7 31 54 33 45 2 50 0 60 -25z m253 -67 c-2
-79 -6 -93 -25 -108 -29 -25 -74 -23 -99 4 -24 26 -26 38 -5 38 8 0 15 -4 15
-10 0 -5 9 -14 21 -20 25 -14 69 11 69 39 0 16 -6 18 -39 14 -48 -7 -81 21
-81 67 0 48 30 70 93 68 l55 -1 -4 -91z m557 72 l20 -23 15 23 c9 12 23 22 31
22 11 0 10 -8 -7 -35 l-22 -35 22 -35 c18 -29 19 -35 6 -35 -8 0 -22 10 -31
23 l-16 22 -16 -22 c-8 -13 -22 -23 -29 -23 -19 0 -18 6 7 40 l22 28 -22 30
c-11 17 -21 33 -21 36 0 14 23 4 41 -16z m1305 -2 c18 -28 16 -107 -3 -114
-10 -3 -13 12 -13 52 0 64 -17 81 -50 51 -14 -13 -20 -31 -20 -62 0 -31 -4
-43 -12 -41 -14 5 -20 57 -13 103 5 28 8 31 50 33 37 1 47 -2 61 -22z"/>
<path d="M2747 4610 c-144 -26 -292 -141 -360 -280 -190 -388 159 -824 581
-725 130 31 231 105 314 229 54 80 72 75 -319 78 l-348 3 -3 22 c-4 24 -2 28
171 325 187 322 190 329 180 339 -13 13 -159 20 -216 9z"/>
<path d="M6205 4590 c-4 -6 11 -41 33 -78 68 -114 224 -384 255 -442 17 -30
39 -67 49 -83 11 -15 18 -37 16 -50 l-3 -22 -352 -3 c-400 -3 -376 4 -320 -85
67 -104 186 -191 302 -222 222 -57 453 35 565 225 69 116 90 252 60 380 -41
175 -182 322 -359 374 -62 18 -236 22 -246 6z"/>
<path d="M7407 2843 c-4 -3 -7 -44 -7 -90 l0 -83 33 0 c20 0 40 7 49 18 35 39
30 118 -10 148 -18 14 -54 18 -65 7z"/>
<path d="M1686 2778 c-10 -40 -6 -76 9 -81 21 -9 27 8 19 58 -7 49 -18 58 -28
23z"/>
<path d="M2154 2855 c-4 -8 -3 -26 0 -39 4 -18 11 -22 31 -19 28 6 42 37 25
58 -16 19 -49 19 -56 0z"/>
<path d="M1601 2179 c-16 -46 -14 -49 19 -49 17 0 30 4 30 9 0 18 -22 71 -30
71 -4 0 -13 -14 -19 -31z"/>
<path d="M3354 2227 c-3 -8 -4 -45 -2 -83 l3 -69 38 1 c53 2 77 27 77 80 0 34
-5 47 -26 63 -30 24 -82 28 -90 8z"/>
<path d="M2612 2158 c-21 -21 -13 -28 27 -28 46 0 61 14 32 29 -26 14 -44 14
-59 -1z"/>
<path d="M3642 2158 c-21 -21 -13 -28 28 -28 22 0 40 4 40 8 0 14 -23 32 -40
32 -9 0 -21 -5 -28 -12z"/>
<path d="M4383 2163 c-7 -2 -13 -11 -13 -19 0 -10 12 -14 40 -14 41 0 49 7 28
28 -12 12 -33 14 -55 5z"/>
<path d="M6281 2157 c-6 -8 -8 -17 -4 -20 9 -10 83 -9 83 1 0 29 -59 43 -79
19z"/>
<path d="M6811 2157 c-6 -8 -8 -17 -4 -20 9 -10 83 -9 83 1 0 29 -59 43 -79
19z"/>
<path d="M7532 2158 c-21 -21 -13 -28 28 -28 28 0 40 4 40 14 0 25 -47 35 -68
14z"/>
<path d="M5178 2158 c-26 -22 -29 -47 -8 -68 25 -25 42 -25 63 -2 22 24 21 45
-1 65 -22 20 -33 21 -54 5z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
